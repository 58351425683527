import './App.css';

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthProvider, ProtectedRoute } from '@kaius/auth';
import { useDispatch } from 'react-redux';
import { routes, identityDomain, appURL, coreProjectId } from '../../config';
import Dashboard from '../Dashboard';
import Projects from '../Projects';
import Onboard from '../Onboard';
import Authentication from '../Authentication';
import ActionTypes from '../../store/action-types';
import Account from '../Account';

const App = () => {
  const dispatch = useDispatch();

  const handleSuccessfulSignin = ({ token, userProfile }) => {
    console.log('Handling success: ', token, userProfile);
    dispatch(ActionTypes.SigninSuccess({ token, userProfile }));
  };

  const handleUserProfileChange = ({ userProfile }) => {
    console.log('Handling success: ', userProfile);
    dispatch(ActionTypes.UserProfileChanged({ userProfile }));
  };

  return (
    <AuthProvider
      authDomain={identityDomain}
      projectId={coreProjectId}
      redirectURI={appURL}
      onSigninSuccess={handleSuccessfulSignin}
      onUserProfileChange={handleUserProfileChange}
    >
      <Switch>
        <Route exact path={routes.HOME} render={() => <div>This is an unprotected route for "Home"</div>} />
        <Route
          exact
          path={routes.DASHBOARD}
          render={() => (
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          )}
        />
        <Route
          path={`${routes.PROJECTS}/:projectId/:environmentId?`}
          render={() => (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path={routes.PROJECTS}
          render={() => (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path={routes.ONBOARD}
          render={() => (
            <ProtectedRoute>
              <Onboard />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path={routes.MY_ACCOUNT}
          render={() => (
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path={routes.AUTHENTICATION}
          render={() => (
            <ProtectedRoute>
              <Authentication />
            </ProtectedRoute>
          )}
        />
        <Route exact path={routes.NOT_FOUND} render={() => <div>Not Found</div>} />
      </Switch>
    </AuthProvider>
  );
};

export default App;

import Container from '@mui/material/Container';
import * as React from 'react';

import { PageWrapper } from '../../components/Layout';

function DashboardContent() {
  return (
    <PageWrapper title="Dashboard">
      <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
        Dashboard
      </Container>
    </PageWrapper>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  list: [],
  error: null,
  selectedProject: null,
  selectedEnvironment: null,
};

const project = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    clearError(state) {
      state.error = null;
    },
    setProjects(state, { payload }) {
      const newState = {
        ...state,
        list: payload,
        isLoading: false,
        error: null,
      };

      return newState;
    },
    setError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
    setSelectedProject(state, { payload }) {
      state.selectedProject = payload;
    },
    setSelectedEnvironment(state, { payload }) {
      state.selectedEnvironment = payload;
    },
  },
});

export const { startLoading, clearError, setProjects, setError, setSelectedProject, setSelectedEnvironment } =
  project.actions;

export default project.reducer;

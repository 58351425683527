import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from '../../config';
import Selectors from '../../store/selectors';

const ADD_NEW = 'add';

export default function ProjectSelect(props) {
  const dispatch = useDispatch();
  const defaultProject = useSelector(Selectors.selectedProject);
  const defaultEnvironment = useSelector(Selectors.selectedEnvironment);

  const { projects = [], containerStyle = {} } = props;

  const [selectedProject, setSelectedProject] = React.useState(defaultProject || '');
  const [selectedEnvironment, setSelectedEnvironment] = React.useState(defaultEnvironment || '');

  const handleChange = (event) => {
    const selectedProjectId = event.target.value;

    // If the user is not trying to add a new project
    if (selectedProjectId !== ADD_NEW) {
      const project = _.find(projects, { id: selectedProjectId });

      if (project) {
        setSelectedProject(project);
        // Default to the first environment
        const firstEnvironment = _.first(project.environments) || '';
        setSelectedEnvironment(firstEnvironment || null);
        dispatch(push(`${routes.PROJECTS}/${selectedProjectId}${firstEnvironment ? `/${firstEnvironment.id}` : ''}`));
      }
    }

    // TODO: Add ability to add new project
  };

  const handleEvironmentChange = (event) => {
    const selectedEnvironmentId = event.target.value;

    // If the user is not trying to add a new environment
    if (selectedEnvironmentId !== ADD_NEW) {
      const environment = _.find(projects?.environments, { id: selectedEnvironmentId });

      if (environment) {
        setSelectedEnvironment(environment);
        dispatch(push(`${routes.PROJECTS}/${selectedProject.id}/${selectedEnvironmentId}`));
      }
    }

    // TODO: Add ability to add new environment
  };

  return (
    <>
      <Box sx={{ ...containerStyle }}>
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small" fullWidth>
          <InputLabel>Project</InputLabel>
          <Select
            value={selectedProject?.id || ''}
            // sx={{ ...containerStyle }}
            size="small"
            label="Project"
            onChange={handleChange}
          >
            {projects.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
            {projects?.length < 1 && (
              <MenuItem disabled selected>
                No Projects
              </MenuItem>
            )}
            <Divider />
            <MenuItem value={ADD_NEW}>Add Project</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ ...containerStyle }}>
        <FormControl sx={{ m: 1, minWidth: 140 }} size="small" fullWidth>
          <InputLabel>Environment</InputLabel>
          <Select
            // sx={{ ...containerStyle }}
            value={selectedEnvironment?.id || ''}
            size="small"
            label="Environment"
            onChange={handleEvironmentChange}
          >
            {selectedProject?.environments?.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
            {selectedProject?.environments?.length < 1 && (
              <MenuItem disabled selected>
                No Environments
              </MenuItem>
            )}
            <Divider />
            <MenuItem value={ADD_NEW}>Add Environment</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

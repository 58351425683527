/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: undefined,
  displayName: undefined,
  company: undefined,
  companyCode: undefined,
  userId: undefined,
  country: undefined,
  email: undefined,
  token: undefined,
  logoutRedirectURL: undefined,
  requestedUrl: undefined,
  isLoading: false,
  isChangingPassword: false,
  error: null,
};

const startLoading = (state) => {
  state.isLoading = true;
  state.error = false;
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserStart: startLoading,
    fetchUserFailure(state, { payload }) {
      state.error = payload;
      state.isLoading = false;
    },
    setUserSuccess(state, { payload }) {
      const newState = {
        ...state,
        ...payload,
        isLoading: false,
        error: null,
      };

      return newState;
    },
    changingPasswordStart(state) {
      state.isChangingPassword = true;
      state.error = null;
    },
    changingPasswordSuccess(state) {
      state.isChangingPassword = false;
      state.error = null;
    },
    setRequestedURL(state, { payload }) {
      state.requestedUrl = payload;
    },
    logoutSuccess(state, { payload }) {
      return {
        ...initialState,
        logoutRedirectURL: payload,
      };
    },
    clearLogoutRedirectURL(state) {
      state.logoutRedirectURL = null;
    },
    resetUserLoadingState(state) {
      state.isLoading = false;
    },
    setError(state, { payload }) {
      state.error = payload;
      state.isLoading = false;
      state.isChangingPassword = false;
    },
  },
});

export const {
  fetchUserStart,
  fetchUserFailure,
  setUserSuccess,
  setRequestedURL,
  logoutSuccess,
  clearLogoutRedirectURL,
  resetUserLoadingState,
  setError,
  changingPasswordStart,
  changingPasswordSuccess,
} = user.actions;

export default user.reducer;

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import userReducer from './slices/user.slice';
import projectsReducer from './slices/projects.slice';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    projects: projectsReducer,
  });

export default rootReducer;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { useAuth } from '@kaius/auth';
import ActionTypes from '../../store/action-types';
import Selectors from '../../store/selectors';

const styles = {
  main: { padding: 10 },

  errorText: { color: 'red', fontSize: 'small' },
};

const validationSchema = yup.object({
  currentPassword: yup
    .string('Please enter your current password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  newPassword: yup
    .string('Please enter your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New password is required'),
  confirmPassword: yup
    .string('Please confirm your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

function GeneralTab({ resetUserPassword }) {
  const [value, setValue] = React.useState(0);
  const { changePasswordWithRedirect } = useAuth();

  const authError = useSelector(Selectors.getUserError);
  const isChangingPassword = useSelector(Selectors.isChangingPassword);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,

    onSubmit: (values) => resetUserPassword(values),
  });

  const handleResetPassword = async () => {
    await changePasswordWithRedirect();
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ flexDirection: 'column' }}>
      <Stack
        sx={{
          width: '25ch',
        }}
        spacing={0.5}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="currentPassword"
          label="Current Password"
          type="password"
          id="currentPassword"
          autoComplete="current-password"
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
          helperText={formik.touched.currentPassword && formik.errors.currentPassword}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type="password"
          id="newPassword"
          autoComplete="new-password"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          autoComplete="new-password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
        <Typography style={styles.errorText}>{authError}</Typography>
        <LoadingButton loading={false} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Change Password
        </LoadingButton>
      </Stack>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  resetUserPassword: (values, callback) => dispatch(ActionTypes.UserChangePasswordRequest(values, callback)),
});

/**
 * Note: This component is intentionally using `connect` to simplify integration with Formik. tsk tsk
 */
export default connect(null, mapDispatchToProps)(GeneralTab);

import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from '../../config';
import ProjectSelect from '../ProjectSelect';
import { projectList } from '../../store/selectors';

const MainMenu = ({ isOpen }) => {
  const dispatch = useDispatch();
  // Get all projects
  const projects = useSelector(projectList);

  const handleAboutLink = () => {
    dispatch(push(routes.ABOUT));
  };
  const handleProjectsLink = () => {
    dispatch(push(routes.PROJECTS));
  };

  const handlAuthenticationLink = () => {
    dispatch(push(routes.AUTHENTICATION));
  };
  return (
    <div>
      {isOpen && <ProjectSelect projects={projects} containerStyle={{ marginRight: 2 }} />}
      <ListItemButton onClick={handleProjectsLink}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
      </ListItemButton>
      <ListItemButton onClick={handlAuthenticationLink}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Authentication" />
      </ListItemButton>
      <ListItemButton onClick={handleAboutLink}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="About" />
      </ListItemButton>
    </div>
  );
};

export default MainMenu;

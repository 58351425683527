import { all, takeEvery, call, put, select, spawn, delay } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { push } from 'connected-react-router';
import ProjectsService from '../../services/project.service';
import {
  startLoading,
  setProjects,
  setError,
  setSelectedEnvironment,
  setSelectedProject,
} from '../slices/projects.slice';
import ActionTypes from '../action-types';

const handleError = function* (error) {
  const { message } = error;
  const { response: { data: { message: apiError } = {} } = {} } = error;
  yield put(setError(apiError || message));
};

export function* fetchProjects() {
  try {
    yield put(startLoading());

    const { data: projects } = yield call(ProjectsService.getProjects);

    yield put(setProjects(projects));
  } catch (e) {
    console.log('Failed to fetch users projects. ', e);
    yield handleError(e);
  }
}

export function* selectProject({ payload }) {
  try {
    const { projectId, environmentId } = payload;

    const response = yield call(ProjectsService.getProject, projectId);

    const { data: project } = response;

    const environment = _.find(project.environments, { id: environmentId });

    yield put(setSelectedProject(project));

    if (environment) {
      yield put(setSelectedEnvironment(environment));
    } else {
      const firstEnvironment = _.first(project.environments);
      if (firstEnvironment) {
        yield put(setSelectedEnvironment(firstEnvironment));
      }
    }
  } catch (error) {
    console.log('Failed to select project', error);
  }
}

/**
 * Create a new project
 * @param {Object} payload - Project object
 * @param {String} payload.name - The name of the project to create
 * @param {String} payload.projectId - Optional - The projectId to use for this project
 * @param {String} payload.organisationId - Optional - The organisation to associate the project to
 */
export function* createProject({ payload }) {
  try {
    const { name, projectId, organsationId } = payload;

    const response = yield call(ProjectsService.createProject, { name, projectId, organsationId });

    const { data: project } = response;

    const [environment] = project;

    yield put(setSelectedProject(project));

    if (environment) {
      yield put(setSelectedEnvironment(environment));
    } else {
      const firstEnvironment = _.first(project.environments);
      if (firstEnvironment) {
        yield put(setSelectedEnvironment(firstEnvironment));
      }
    }
  } catch (error) {
    console.log('Failed to create project', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FetchProjects, fetchProjects),
    takeEvery(ActionTypes.SelectProject, selectProject),
    takeEvery(ActionTypes.CreateProject, createProject),
  ]);
}

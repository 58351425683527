import { createAction } from '@reduxjs/toolkit';

export default {
  AppInit: createAction('app/init'),
  FetchProjects: createAction('projects/fetchAll'),
  SelectProject: createAction('projects/select'),
  CreateProject: createAction('projects/create'),
  SigninSuccess: createAction('user/siginSuccess'),
  UserProfileChanged: createAction('user/userProfileChanged'),
  UserChangePasswordRequest: createAction('user/userChangePasswordRequest'),
};

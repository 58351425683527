import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useAuth } from '@kaius/auth';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import ProjectSelect from '../ProjectSelect';
import MainMenu from './MainMenu';
import Copyright from '../Copyright';
import ActionTypes from '../../store/action-types';
import { projectList } from '../../store/selectors';
import { routes } from '../../config';

const SiteLayout = ({ children }) => children;

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const styles = {
  mainContainer: { display: 'flex', minHeight: '100vh' },
  toolbar: {
    pr: '24px', // keep right padding when drawer closed
  },
  childContainer: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 165px)',
    marginTop: '30px',
    marginLeft: 4,
    marginRight: 4,
  },
  menuProfileName: { marginLeft: 12 },
};

export const PageWrapper = ({ children, title, showCopyright = true }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { signout, hasOnboarded: userHasOnboarded, userProfile } = useAuth();
  const projects = useSelector(projectList);

  console.log('User Profile: ', userProfile);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccount = () => {
    setAnchorEl(null);
    dispatch(push(routes.MY_ACCOUNT));
  };

  const handleLogout = () => {
    signout();
  };

  useEffect(() => {
    dispatch(ActionTypes.FetchProjects());
  }, [dispatch]);

  return (
    <div>
      <Box sx={styles.mainContainer}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={styles.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {title}
            </Typography>
            {userHasOnboarded && !open && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <ProjectSelect projects={projects} containerStyle={{ marginRight: 1 }} />
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} /> {/** Spacer to force the profile icon right */}
            <div>
              <IconButton
                size="large"
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={userProfile.displayName} src={userProfile.profileUrl} sx={{ width: 32, height: 32 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem disableRipple>
                  <Avatar alt={userProfile.displayName} src={userProfile.profileUrl} sx={{ width: 32, height: 32 }} />
                  <div style={styles.menuProfileName}>
                    <Typography>{userProfile.displayName}</Typography>
                  </div>
                </MenuItem>
                <Divider />

                <MenuItem dense onClick={handleMyAccount}>
                  Account
                </MenuItem>

                <Divider />
                <MenuItem dense onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" dense>
            <MainMenu isOpen={open} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
          }}
        >
          <Toolbar />

          <Box sx={styles.childContainer}>{children}</Box>
          {showCopyright && (
            <Box
              component="footer"
              sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
              }}
            >
              <Container maxWidth="sm">
                <Copyright />
              </Container>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default { AppBar, Drawer, PageWrapper };

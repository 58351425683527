import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import { push } from 'connected-react-router';
import { useAuth } from '@kaius/auth';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Button, Divider } from '@mui/material';
import { PageWrapper } from '../../components/Layout/Layout';
import { routes } from '../../config';
import ActionTypes from '../../store/action-types';

const steps = ['Create your first project', 'Select your services', 'Create your environments'];

/**
 *
 * We need to think about a few things here;
 *
 * - Allow the user to select the region(s)/global for their project.
 * - Provide a list of supported databases
 * - Do we allow them to enable cache (redis) or is this provided as part of the service?
 * - How many environments do we want to support? Max 5?
 *
 *
 */

const validationSchema = yup.object({
  projectName: yup
    .string('Enter a project name')
    .min(2, 'Project name should be at least 2 characters long')
    .required('Project name is required'),
  projectId: yup
    .string('Enter your project id')
    .min(2, 'Project id should be at least 2 characters long')
    .required('Project id is required'),
});

export default function Projects() {
  const dispatch = useDispatch();
  const { hasOnboarded } = useAuth();

  useEffect(() => {
    if (hasOnboarded) {
      dispatch(push(routes.DASHBOARD));
    }
  }, [dispatch, hasOnboarded]);

  const formik = useFormik({
    initialValues: {
      projectName: 'My First Project',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(ActionTypes.CreateProject(values));
    },
  });

  return (
    <PageWrapper title="">
      <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Typography variant="h1">Create your first project</Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="projectName"
                name="projectName"
                label="Project Name"
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={formik.touched.projectName && Boolean(formik.errors.projectName)}
                helperText={formik.touched.projectName && formik.errors.projectName}
              />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" type="submit">
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper> */}
      </Container>
    </PageWrapper>
  );
}

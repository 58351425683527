import pkg from '../../package.json';

export const environment = process.env.NODE_ENV || 'development';

export const apiURL = process.env.REACT_APP_API_URL || 'https://kaius.jmg.local:3010/';

// This is the URL to this web app
export const appURL = process.env.REACT_APP_APP_URL || 'https://kaius.jmg.local:3020'; // 'https://www.kaius.io/';

export const coreProjectId = process.env.REACT_APP_CORE_PROJECT_ID || 'kaius';

export const identityDomain = process.env.REACT_APP_IDENTITY_DOMAIN || 'kaius.jmg.local:3025';

export const correlationHeaderName = process.env.REACT_APP_CORRELATION_HEADER_NAME || 'x-correlation-id';

export const enforceAuthentication = true;

export const routes = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  AUTHENTICATION: '/authentication',
  NOT_FOUND: '/notfound',
  UNAUTHORISED: '/unauthorised',
  LOGIN: '/login',
  LOGOUT: '/logout',
  ONBOARD: '/onboard',
  MY_ACCOUNT: '/account',
};

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const appVersion = process.env.REACT_APP_APPLICATION_VERSION || pkg.version;

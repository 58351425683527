export const userToken = (state) => state.user.token;
export const isProjectLoading = (state) => state.project.isLoading;
export const projectList = (state) => state.projects.list;
export const selectedProject = (state) => state.projects.selectedProject;
export const selectedEnvironment = (state) => state.projects.selectedEnvironment;
export const getUserError = (state) => state.user.error;
export const isChangingPassword = (state) => state.user.isChangingPassword;

export default {
  userToken,
  isProjectLoading,
  projectList,
  selectedProject,
  selectedEnvironment,
  getUserError,
  isChangingPassword,
};

import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { changePassword } from '@kaius/auth';
import { setError, changingPasswordStart, changingPasswordSuccess } from '../slices/user.slice';
import ActionTypes from '../action-types';
import { routes } from '../../config';

const handleError = function* (error) {
  const { message } = error;
  const { response: { data: { message: apiError } = {} } = {} } = error;
  yield put(setError(apiError || message));
};

function* handleOnboardCheck(userProfile) {
  try {
    const { hasOnboarded } = userProfile;

    console.log('profile/onboard', userProfile);
    if (!hasOnboarded) {
      yield put(push(routes.ONBOARD));
    }
  } catch (error) {
    console.log('Failed to re-route user for onboarding', error);
  }
}

export function* signinSuccess({ payload }) {
  try {
    yield call(handleOnboardCheck, payload);
    // yield put(push(routes.DASHBOARD));
  } catch (error) {
    console.log('Failed process signinSuccess event', error);
  }
}

export function* userProfileChanged({ payload }) {
  try {
    const { userProfile } = payload;
    yield call(handleOnboardCheck, userProfile);
  } catch (error) {
    console.log('Failed process userProfileChanged event', error);
  }
}

export function* userChangePasswordRequest({ payload }) {
  try {
    yield put(changingPasswordStart());

    const { currentPassword, newPassword, confirmPassword } = payload;

    yield call(changePassword, currentPassword, newPassword, confirmPassword);

    yield put(changingPasswordSuccess(null));

    toast.success(`Password changed successfully.`, {
      autoClose: true,
    });
  } catch (error) {
    yield handleError(error);
    console.log('Failed execute userChangePasswordRequest', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.SigninSuccess, signinSuccess),
    takeEvery(ActionTypes.UserProfileChanged, userProfileChanged),
    takeEvery(ActionTypes.UserChangePasswordRequest, userChangePasswordRequest),
  ]);
}

import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// CURRENT
// https://www.canva.com/colors/color-palettes/bubblegum-sweetness/

// OLD
// https://www.canva.com/colors/color-palettes/rainbow-doughnuts/

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#37BEB0',
      contrastText: '#ffffff',
      dark: '#0C6170',
      light: '#0C6170',
    },
    secondary: {
      main: '#A4E5E0',
      dark: '#37BEB0',
      light: '#37BEB0',
      contrastText: '#8A6FDF',
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
  },
});

export default theme;

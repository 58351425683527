import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActionTypes from '../../store/action-types';

import { PageWrapper } from '../../components/Layout/Layout';

export default function Projects() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  console.log('Location; ', location);

  useEffect(() => {
    console.log('Params; ', params);
    const { projectId, environmentId } = params;
    dispatch(ActionTypes.SelectProject({ projectId, environmentId }));
  }, [params, dispatch]);
  return (
    <PageWrapper title="">
      <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }} />
    </PageWrapper>
  );
}

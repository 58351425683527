import api from './api';

export const getProjects = async () => api.get(`projects`);
export const getProject = async (projectId) => api.get(`projects/${projectId}`);
export const createProject = async (payload) => api.post(`projects`, payload);

export default {
  getProject,
  getProjects,
  createProject,
};

import { getAccessToken } from '@kaius/auth';
import api from '../../services/api';

export default (store) => (next) => (action) => {
  api.session.interceptors.request.use(
    (config) => {
      const token = getAccessToken();

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return next(action);
};
